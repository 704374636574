import React, { useEffect } from 'react';

import { Layout } from '@app/components/Layout';
import { ArticleCard } from '@app/components/article/Article';
import { useSelector, useDispatch } from '@app/providers/store';
import { getLatestArticles } from '@app/providers/store/articles/actions';

import {
  Main,
  Spacer,
  BlogTitle,
  BlogHeader,
  BlogCounter,
  BlogWrapper,
  FooterSpacer,
  ContentWrapper,
  MaxWidthWrapper,
  DarkHeaderBackground,
} from './articles.style';

export const Articles: React.FC = () => {
  const dispatch = useDispatch();
  const { data: articles, meta_data, isLoading } = useSelector(({ articles }) => articles);

  useEffect(() => {
    if (!articles?.length) {
      getLatestArticles(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles.length]);

  const pluralizedCount = `${meta_data?.totalDocs} Article${meta_data?.totalDocs === 1 ? '' : 's'}`;

  return (
    <Layout isLoading={isLoading}>
      <DarkHeaderBackground />
      <MaxWidthWrapper>
        <Main>
          {meta_data?.totalDocs ? (
            <BlogWrapper>
              <BlogHeader>
                <BlogTitle>Latest Content</BlogTitle>
                <BlogCounter>{pluralizedCount}</BlogCounter>
              </BlogHeader>
              <ContentWrapper>
                {articles.map((article) => (
                  <ArticleCard {...article} key={article._id} />
                ))}
              </ContentWrapper>
              <Spacer />
            </BlogWrapper>
          ) : null}
        </Main>
      </MaxWidthWrapper>
      <Spacer />
      <Spacer />
      <FooterSpacer />
    </Layout>
  );
};
