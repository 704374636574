import React from 'react';

import {
  Span,
  ArrowSvg,
  BlogCardArrow,
  BlogCardArrowTarget,
  BlogCardArrowWrapper,
} from './PoppingArrow.style';

import useSound from '@app/hooks/use-sound-hook';
import useDesktopState from '@app/hooks/use-desktop-state.hook';

type Arrow = {
  children: string;
  increaseTargetBy: number;
  shouldShowFirstArrow: boolean;
};

const ARROW_DELAY = 125;

const PoppingArrow: React.FC<Arrow> = ({ children, increaseTargetBy, shouldShowFirstArrow }) => {
  const [isHovering, setIsHovering] = useDesktopState(false);
  const [playRising, { stop }] = useSound('/sounds/rising-pops.mp3', {
    volume: 1,
  });

  return (
    <BlogCardArrowWrapper
      onMouseEnter={() => {
        setIsHovering(true);
        playRising();
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        stop();
      }}
    >
      <BlogCardArrow
        style={{
          top: -increaseTargetBy,
          left: -increaseTargetBy,
          right: increaseTargetBy,
          bottom: -increaseTargetBy,
        }}
      />
      <Span>{children}</Span>

      <BlogCardArrowTarget>
        <ArrowSvg width="36" height="12" viewBox="0 0 36 12" fill="none">
          <path
            d="M0.75 6H11.25 M6 0.75L11.25 6L6 11.25"
            stroke="hsl(260deg, 100%, 55%)"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              opacity: shouldShowFirstArrow ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms`,
            }}
          />

          <path
            d="M15 10L19.5 5.5L15 1"
            stroke="hsl(325deg, 100%, 48%)"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms`,
            }}
          />
          <path
            d="M23 10L27.5 5.5L23 1"
            stroke="hsl(260deg, 100%, 55%)"
            strokeOpacity="0.66"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms ${ARROW_DELAY}ms`,
            }}
          />
          <path
            d="M31 10L35.5 5.5L31 1"
            stroke="var(--color-primary)"
            strokeOpacity="0.35"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{
              opacity: isHovering ? 1 : 0,
              transition: `opacity ${isHovering ? 0 : ARROW_DELAY}ms ${ARROW_DELAY * 2}ms`,
            }}
          />
        </ArrowSvg>
      </BlogCardArrowTarget>
    </BlogCardArrowWrapper>
  );
};

export default PoppingArrow;
