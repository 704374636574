import styled from 'styled-components';

export const Next = styled.div`
  isolation: isolate;
  @media (max-width: 37.5em) {
    font-size: 50%;
  }

  @media (max-width: 75em) {
    font-size: 56.25%;
  }
`;

export const MaxWidthWrapper = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  max-width: 23rem;
  text-align: center;
  line-height: 3.8rem;
  color: ${(p) => p.theme.colors.TEXT};

  @media (min-width: 1024px) {
    font-size: 7.5rem;
  }

  @media (min-width: 768px) {
    font-size: 5rem;
    line-height: 3.8vw;
  }

  @media (min-width: 576px) {
    font-size: 4rem;
  }

  @media (min-width: 411px) {
    max-width: max-content;
  }
`;

export const Description = styled.p`
  margin-top: 3vw;
  max-width: 27rem;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${(p) => p.theme.colors.GRAY_700};

  @media (min-width: 75em) {
    margin-top: 2vw;
  }

  @media (min-width: 1024px) {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  @media (min-width: 768px) {
    font-size: 1.3rem;
    line-height: 2.1rem;
    max-width: max-content;
  }
`;

export const ExternalLink = styled.button`
  height: 40px;
  display: flex;
  cursor: pointer;
  font-weight: 300;
  padding: 0px 4rem;
  font-size: 0.8rem;
  width: fit-content;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  color: rgb(255, 255, 255);
  padding: 0.625rem 2.25rem;
  border: 1px solid transparent;
  background-color: rgb(125, 105, 255);

  @media (max-width: 576px) {
    margin-top: 0rem;
  }
`;

export const LineBreak = styled.br``;
