import { ARTICLE_SUCCESS, ARTICLES_FAILURE, ARTICLES_REQUEST, ARTICLES_SUCCESS } from '../types';

import {
  Article,
  IArticlesFailure,
  IArticlesStarted,
  ISetArticleSuccess,
  ISetArticlesSuccess,
} from '../models';
import { DispatchTypes } from '../../interface';
import { getArticles, getArticle } from '@app/network/articles';

export const articlesRequest = (): IArticlesStarted => ({
  type: ARTICLES_REQUEST,
});

export const articlesFailure = (error: IArticlesFailure['payload']): IArticlesFailure => ({
  type: ARTICLES_FAILURE,
  payload: error,
});

export const articlesSuccess = (articles: ISetArticlesSuccess['payload']): ISetArticlesSuccess => ({
  type: ARTICLES_SUCCESS,
  payload: articles,
});

export const articleSuccess = (article: Article): ISetArticleSuccess => ({
  type: ARTICLE_SUCCESS,
  payload: article,
});

export const getLatestArticles = async (dispatch: React.Dispatch<DispatchTypes>) => {
  dispatch(articlesRequest());
  try {
    const articles = await getArticles();
    dispatch(articlesSuccess(articles.payload));
  } catch (error: any) {
    dispatch(articlesFailure(error));
  }
};

export const getSingleArticle = (slug: Article['slug'], callback?: (error: Error) => void) => {
  return async (dispatch: React.Dispatch<DispatchTypes>) => {
    dispatch(articlesRequest());
    try {
      const article = await getArticle(slug);
      dispatch(articleSuccess(article.payload));
    } catch (error: any) {
      callback?.(error);
      dispatch(articlesFailure(error));
    }
  };
};
