import { Link } from 'react-scroll';
import styled, { css, createGlobalStyle } from 'styled-components';

export { FooterSpacer, Spacer } from '@app/pages/articles/articles.style';

export const Next = styled.div`
  isolation: isolate;
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.DETAILS_BACKGROUND};
`;

export const LayoutWrapper = styled.div`
  display: flex;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  transition: background 350ms ease 0s;
`;

export const HeroWrapper = styled.div`
  z-index: 4;
  position: relative;
`;

export const LightHeaderBackground = styled.div`
  z-index: 4;
  top: 0px;
  width: 100%;
  height: 68px;
  position: sticky;
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.MUTED};
`;

export const SneakyHeaderBackground = styled.div`
  z-index: 3;
  top: -68px;
  width: 100%;
  height: 68px;
  position: absolute;
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.MUTED};
`;

export const HeroSection = styled.div`
  padding-top: 48px;
  padding-bottom: 36px;
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.MUTED};
`;

export const MaxWidthWrapper = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: 563px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Title = styled.h1`
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: calc(2.375rem);
  font-weight: var(--font-weight-medium);

  @media (max-width: 563px) {
    margin-bottom: 1.5rem;
  }
`;

export const SubTitle = styled.h4`
  color: #6c7693;
  margin-top: -12px;
  margin-bottom: 16px;
  font-weight: var(--font-weight-book);

  .small-heading {
    font-size: calc(1.375rem);
  }
`;

export const DarkHeaderBackground = styled.div`
  top: 0px;
  z-index: 3;
  width: 100%;
  position: sticky;
  height: calc(70px);
  will-change: transform;
  transform: translateY(-2px);
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.DETAILS_BACKGROUND};
`;

export const Main = styled.div`
  z-index: 1;
  position: relative;
`;

export const Target = styled.div`
  contain: none;
  scroll-margin-top: 64px;
`;

export const MainWrapper = styled.div`
  display: flex;
  padding-top: 48px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  justify-content: center;
  align-items: flex-start;
  -webkit-box-pack: center;
  flex-direction: row-reverse;
  position: relative;

  .main-section {
    z-index: 2;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-width: 563px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Aside = styled.aside`
  top: 148px;
  overflow: auto;
  margin-top: 4px;
  position: sticky;
  margin-left: auto;
  flex: 0 100000 250px;
  padding-bottom: 16px;
  max-height: calc(100vh - 148px);

  @media (max-width: 1084px) {
    display: none;
  }
`;

export const TableOfContent = styled.nav`
  margin-bottom: 32px;

  .table_of_content_link {
    // opacity: 1;
    color: ${(p) => p.theme.colors.PRIMARY};
  }
`;

export const Heading = styled.h2`
  letter-spacing: 2px;
  margin-bottom: 18px;
  font-size: calc(1rem);
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  color: ${(p) => p.theme.colors.GRAY_900};

  .tableOfContent__heading {
    margin-bottom: 16px;
    font-weight: var(--font-weight-regular);
    color: ${(p) => p.theme.colors.GRAY_900};
  }
`;

export const ContentLink = styled(Link)`
  opacity: 0.7;
  display: block;
  font-size: 15px;
  margin-top: 8px;
  text-decoration: none;
  color: ${(p) => p.theme.colors.TEXT};
  transition: opacity 500ms ease 0s;
  font-weight: var(--font-weight-light);
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const ArticleContent = styled.article`
  flex: 1 1 686px;
  max-width: min(686px, 100%);
  margin-top: -20px;
  `;

export const ContentFooter = styled.div`
  display: flex;
  padding-top: 32px;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

export const DateWrapper = styled.div`
  flex: 2 1 0%;
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-regular);
`;

export const DatePrefix = styled.h3`
  font-size: 13px;
  color: #6c7693;
  text-transform: uppercase;
  font-weight: var(--font-weight-regular);
`;

export const DateTitle = styled.p`
  font-size: 21px;
  font-weight: var(--font-weight-regular);

  @media (max-width: 563px) {
    font-size: 18px;
  }
`;

export const HitWrapper = styled.div`
  display: flex;
  min-height: 72px;
  text-align: right;
  flex-direction: column;
`;

export const Prefix = styled.h3`
  color: #6c7693;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: var(--font-weight-light);
`;

export const CounterWrapper = styled.div`
  position: relative;
  width: 98px;
  margin-right: -5px;

  .unstyled__btn {
    top: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
  }

  .HitCounter__Button {
    display: block;
    margin: 0px;
    padding: 5px;
    border: none;
    font: inherit;
    cursor: pointer;
    text-align: left;
    font-size: 30px;
    color: hsl(225deg, 7%, 60%);
    transition: background 350ms ease 0s;
    background: ${({ theme }) => theme.colors.MUTED};
    opacity: 1;
    font-weight: var(--font-weight-light);

    @media (max-width: 563px) {
      font-size: 27px;
    }
  }
`;

export const BodyBack = createGlobalStyle`
   ${css`
     body {
       position: relative;
       background: ${({ theme }) => theme.colors.MUTED};
     }
   `}
`;
