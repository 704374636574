import { NavLink } from 'react-router-dom';

import { LogoText, Image, LogoWrapper } from './Logo.style';

export const Logo = () => (
  <LogoWrapper>
    <NavLink to="/">
      <div className="logo-link">
        <Image alt="Logo" src="/images/logopng.png" />
        <LogoText>mommyandmecare</LogoText>
      </div>
    </NavLink>
  </LogoWrapper>
);
