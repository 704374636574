import React, { useReducer, useContext, useEffect } from 'react';
import combineReducers from 'react-combine-reducers';

import { settingReducer, initialSettingsState } from './settings/reducers';
import { articlesReducer, initialArticlesState } from './articles/reducers';

import StoreContext, { ContextProps } from './interface';
import { usePersistedState } from '../../hooks/use-persisted-state.hook';

type StoreProviderProps = { children: React.ReactNode };

const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
  const [persistedSettings, setPersistedSettings] = usePersistedState(
    'settings',
    initialSettingsState
  );

  const [storeReducer, initialStore] = combineReducers({
    settings: [settingReducer, persistedSettings],
    articles: [articlesReducer, initialArticlesState],
  });

  const [store, dispatch] = useReducer(storeReducer, initialStore) as [
    ContextProps['store'],
    ContextProps['dispatch']
  ];

  useEffect(() => {
    setPersistedSettings(store.settings);
  }, [setPersistedSettings, store]);

  return <StoreContext.Provider value={{ store, dispatch }}>{children}</StoreContext.Provider>;
};

function useSelector<TSelected = unknown>(
  selector: (state: ContextProps['store']) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  const { store } = useContext(StoreContext);
  return selector(store);
}

const useStore = () => {
  const { store } = useContext(StoreContext);
  return store;
};

const useDispatch = () => {
  const { dispatch } = useContext(StoreContext);
  return dispatch;
};

export { StoreProvider, useStore, useDispatch, useSelector };
