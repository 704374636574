import styled from "styled-components";

export const BlogCardArrowWrapper = styled.div`
  font-size: 1rem;
  position: relative;
  align-items: center;
  display: inline-flex;
  font-weight: var(--font-weight-bold);
  font-weight: var(--font-weight-medium);
`;

export const BlogCardArrow = styled.div`
  position: absolute;
  z-index: 0;
`;

export const Span = styled.span`
  cursor: pointer;
`;

export const BlogCardArrowTarget = styled.div`
  margin-left: 8px;
  position: relative;
`;

export const ArrowSvg = styled.svg`
  top: 0;
  left: 0;
  right: 0;
  max-width: unset;
  position: absolute;
  transform: translateY(-6px);
`;
