import styled, { keyframes } from 'styled-components';

import { H2 } from '../Heading';
import { Paragraph } from '../paragraph';

export { Spacer } from '@app/pages/articles/articles.style';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const SectionTitle = styled(H2)`
  line-height: 1.2;
  margin-bottom: 24px;
  font-size: calc(28 / 16 * 1rem);
  color: ${(p) => p.theme.colors.GRAY_1000};
`;

export const SignupForm = styled.form`
  display: flex;
  margin-top: 64px;
  margin-bottom: 8px;
  align-items: flex-end;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const NameCol = styled.div`
  flex: 2;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const EmailCol = styled.div`
  flex: 3;
  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  position: relative;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const Description = styled(Paragraph)`
  margin-top: 16px;
  max-width: 34rem;
  margin-bottom: 1.5rem;
  font-size: calc(1.1875rem);

  em {
    font-weight: 500;
  }
`;

export const UnstyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  display: block;
  cursor: pointer;
  text-align: left;
  background: transparent;

  &:focus {
    outline-offset: 2px;
    outline: 2px auto ${(p) => p.theme.colors.PRIMARY};
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

export const ButtonElem = styled(UnstyledButton)`
  color: white;
  display: flex;
  height: 60px;
  width: 175px;
  font-size: 18px;
  position: relative;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  background: hsl(255deg, 85%, 30%);
  font-weight: var(--font-weight-medium);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);

  &:disabled {
    opacity: 0.5;
  }
`;

export const Button = styled(UnstyledButton)`
  color: white;
  height: 60px;
  width: 175px;
  display: flex;
  font-size: 18px;
  position: relative;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  background: hsl(255deg, 85%, 30%);
  font-weight: var(--font-weight-medium);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);

  &:disabled {
    opacity: 0.5;
  }
`;

export const Modal = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  min-height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.hexToRGB('#000000', p.theme.isDarkMode ? 0.8 : 0.5)};
`;

export const SuccessContainer = styled.div`
  width: 90%;
  display: flex;
  padding: 20px;
  height: 250px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};

  button {
    flex: 0;
    height: 40px;
    width: 110px;
    margin-top: 10px;
    font-size: small;
    border-radius: 5px;

    @media ${(p) => p.theme.breakpoints.xsAndLarger} {
      padding: 3px;
      margin-top: 20px;
    }
  }

  @media (min-width: 768px) {
    padding: 20px 30px;
  }

  ::before {
    right: -27%;
    content: '';
    width: 200px;
    height: 100px;
    position: absolute;
    transform: rotate(250deg);
    background-position: center;
    background-repeat: no-repeat;
    background: url('/images/bottom-curve.svg');

    @media (max-width: 380px) {
      right: -35%;
    }

    @media (max-width: 320px) {
      right: -40%;
    }
  }

  p {
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
  }

  h4 {
    z-index: 5;

    @media (max-width: 380px) {
      max-width: 200px;
    }
  }
`;
