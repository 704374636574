import React, { InputHTMLAttributes } from 'react';
import { generateId } from '@app/helpers';

import { Border, Input, Label, Wrapper } from './TextInput.style';

type ITextInput = {
  id: string;
  label: string;
  autoComplete: string;
} & InputHTMLAttributes<HTMLInputElement>;

const TextInput: React.FC<ITextInput> = ({ id, label, disabled, ...delegated }) => {
  const { current: actualId } = React.useRef(id || generateId());
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <Wrapper>
      <Label
        htmlFor={actualId}
        style={{
          opacity: 1,
          color: isFocused ? 'hsl(245deg, 100%, 60%)' : 'var(--color-text)',
        }}
      >
        {label}
      </Label>
      <br />

      <Input
        {...delegated}
        required
        id={actualId}
        disabled={disabled}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Border
        style={
          isFocused
            ? {
                opacity: 1,
                transform: 'translateY(6px)',
                background: 'hsl(245deg, 100%, 60%)',
              }
            : disabled
            ? { opacity: 0.4 }
            : {}
        }
      />
    </Wrapper>
  );
};

export default TextInput;
