import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { toast } from 'react-hot-toast';

import { subscribeToNewsLetter } from '@app/network';
import TextInput from '../textInput/TextInput';

import {
  Modal,
  Spacer,
  Button,
  Wrapper,
  NameCol,
  EmailCol,
  SignupForm,
  ButtonElem,
  Description,
  SectionTitle,
  SuccessContainer,
} from './NewsletterSignUp.style';

const defaultForm = { full_name: '', email_address: '' };

export const NewsLetterSignUp: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [form, setForm] = useState<typeof defaultForm>({ ...defaultForm });

  const handleChange = (field: keyof typeof defaultForm) => {
    return ({ target }: any) => {
      setForm({ ...form, [field]: target.value });
    };
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      await subscribeToNewsLetter(form);
      setIsSuccessful(true);
      toast.success('Subscription successful!');
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsSuccessful(false);
    setForm({ ...defaultForm });
  };

  return (
    <Wrapper>
      <SectionTitle>The Wallet newsletter that sparks joy</SectionTitle>
      <Description>
        Our goal with this blog is to create helpful content for our end users, and this newsletter
        is no different! We'll let you know when We publish new content, and We'll even share
        <em>exclusive newsletter-only content</em> now and then.
        <br />
        <br />
        No spam, unsubscribe at any time.
      </Description>

      <SignupForm onSubmit={handleSubmit}>
        <NameCol>
          <TextInput
            required
            type="name"
            id="full_name"
            label="First Name"
            disabled={isLoading}
            value={form.full_name}
            autoComplete="given-name"
            onChange={handleChange('full_name')}
          />
        </NameCol>
        <Spacer />
        <EmailCol>
          <TextInput
            required
            type="email"
            label="Email"
            id="email_address"
            disabled={isLoading}
            autoComplete="email"
            value={form.email_address}
            onChange={handleChange('email_address')}
          />
        </EmailCol>
        <Spacer />
        <ButtonElem>Subscribe</ButtonElem>
      </SignupForm>

      {isSuccessful && (
        <Portal>
          <Modal onClick={closeModal}>
            <SuccessContainer>
              <Spacer size={10} />
              <h4>Success!</h4>
              <h5>You are now a subscribed member</h5>

              <Spacer size={20} />
              <p>
                You're in! Keep an eye out for a welcome email! No confirmation is necessary, but
                I'll include some of my most popular free content. 😄
              </p>
              <Spacer size={15} />
              <Button>Got it!</Button>
            </SuccessContainer>
          </Modal>
        </Portal>
      )}
    </Wrapper>
  );
};
