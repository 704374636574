import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import { Layout } from '@app/components/Layout';
import { renderMdxComponents } from '@app/helpers';
import { TERMS_OF_USE } from '@app/constants/terms_of_use';

import {
  Next,
  Main,
  Title,
  Spacer,
  Target,
  BodyBack,
  HeroSection,
  HeroWrapper,
  MainWrapper,
  FooterSpacer,
  LayoutWrapper,
  ArticleContent,
  MaxWidthWrapper,
  DarkHeaderBackground,
  LightHeaderBackground,
  SneakyHeaderBackground,
} from './terms.styles';

export const TermsOfUse: React.FC = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Layout>
      <Next>
        <BodyBack />
        <LayoutWrapper />
        <HeroWrapper>
          <LightHeaderBackground />
          <SneakyHeaderBackground />
          <HeroSection>
            <MaxWidthWrapper>
              <Title>Terms of use</Title>
            </MaxWidthWrapper>
          </HeroSection>
        </HeroWrapper>
        <DarkHeaderBackground />
        <LayoutWrapper>
          <Main>
            <Target />
            <MainWrapper className="main-section">
              <ArticleContent>
                <ReactMarkdown
                  //@ts-ignore
                  components={renderMdxComponents({})}
                >
                  {TERMS_OF_USE}
                </ReactMarkdown>
                <Spacer />
              </ArticleContent>
            </MainWrapper>
          </Main>
        </LayoutWrapper>

        <Spacer />
        <Spacer />
        <Spacer />
        <FooterSpacer />
      </Next>
    </Layout>
  );
};
