export const PRIVACY_POLICY = `
## **MommyandMeCare Privacy Policy**


**1. Introduction**

At MommyandMeCare Inc.. (MommyandMeCare), we value your privacy and we are committed to safeguarding your personal information. All personal information that you provide us will be protected and kept confidential among our affiliates, representatives, and privies.

Throughout the website, the terms “we”, “us” and “our” refer to MommyandMeCare.

This Privacy Policy explains how we collect, use, share and protect your personal information in connection with your relationship with us as a client or potential client. It applies to all our clients, potential clients, consultants, partners and every other person we hold information about.

This policy also sets out your rights and who you may contact for further information. You agree to this Privacy Policy by visiting our website and when you use our services.

Our website is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to local law or regulation.

**2. Age Restriction**

You affirm that you are over the age of 18 or the applicable legal age for conducting transactions in your country and have the right to contract in your own name, and that you have read the above authorization and fully understand its contents.

**3. Information We Collect.**

There are two categories of information we collect. We collect a variety of information from our users and visitors to our website. As described below, some information is automatically collected when you visit our website, and some you provide to us when registering an account or filling out a form, or communicating with us.

i. Information Collected Automatically: Whenever you visit our website, our

Web servers automatically collect non-personal information such as the domain name of the internet access provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, and statistics.

ii. Information You Provide Us: If you provide us with personal information by contacting us, we collect that personal information.

**4. Using Your Personally Identifiable Information**

“Personally Identifiable Information” means any information that (a) identifies or can be used to identify, contact, or locate the person to whom such information pertains or (b) from which identification or contact information of an individual person can be derived. This includes but is not limited to your name, email address, phone number, physical address, assets and debts, next-of-kin/beneficiary’s details (name and email address), and cryptocurrency wallet information.

We primarily collect your information to ensure that we provide the most efficient service to you, monitor the use and improve our website and other legitimate interests. Your information will solely be used and disclosed for the following purposes:

I. To help us verify your identity;

II. To carry out our obligations ensuing from any contracts entered into between you and us;

III. To provide you with the products, services and information you request from

IV. To assist you with enquiries and improve our customer service;

V. To assist us in carrying out marketing analysis and customer profiling (including transactional information), conduct research, including creating statistical and testing information;

VI. To allow us to communicate with you in any way (including e-mail, telephone, visit, and text or multimedia messages);

VII. For our billing and account purposes;

VII. To help prevent and detect fraud or loss;

IX. To update our records;

X. To make recommendations and suggestions to you about services offered by us unless you have previously asked us not to do so;

XI. Send you service or support messages, such as updates, security alerts, email notifications and /or newsletters;

XII. Conduct investigations and risk assessments; and

XIII. For compliance with legal and regulatory obligations.

5. Other Information We Collect

Other information which may be automatically collected from you when you visit our website include; the domain name of your internet access provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, your geographical location, operating system, referral source, and other relevant statistics.

We may also collect information from you using cookies and other analytical tools especially when you use our products and services. More details are provided below in our section on Cookies.

**6. Service Providers**

i. We employ third-party companies or individuals (“Exchange Platforms”) to provide trading and exchange services to you. We will not make any transactions on your behalf.

ii. Your engagement of Exchange Platforms via our platform does not constitute a partnership with us and you recognize that MommyandMeCare is not a party to any transactions done outside our Platform.

**7. Communications from our site**

Pursuant to you registering on our site, you will receive emails that confirm specific actions you requested. You will receive notifications confirming your registration, and the successful verification of email addresses from time to time, we will also send newsletters, user surveys, requests for user feedback regarding user experience and site operations or marketing offers from us or from us. The completion of these surveys or requests for feedback or acceptance of any offer is strictly voluntary. If you do not wish to receive these offers, surveys or user feedback emails, please opt out in any offer email you receive from us.

**8. Administrative Access to your Information**

We operate strict internal procedures to ensure that your information is well protected. In accordance with our internal guidelines, our employees are not permitted to access your account or your information except for the information necessary to solve issues you may raise, such as triggering confirmation emails. We log and regularly audit all access to your MommyandMeCare account.

**9. International Transfers**

The transfer of your information to a foreign country or international organization will only be done with your consent and in accordance with applicable laws. We will ensure that there are adequate data protection laws in the recipient country or organization before transferring your information.

**10. Your Rights**

Subject to certain limitations and exceptions, you are entitled to the following principal rights in accordance with the applicable laws:

i. You have the right to be notified if we are transferring your personal information.

ii. You have the right to request the erasure of your personal information at any time.

iii. You have the right to request that we rectify inaccurate personal information.

iv. You may request at any time that we halt further dissemination of your data or cease to use your personal information.

v. You have the right to request for copies of your personal information.

vi. You have the right to request that we restrict the processing of your personal information.

11. Website Security

We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures such as to safeguard and secure the information we collect online.

In addition to our own substantial efforts, you can take several precautions to protect the security of your computer and personal information. For instance, when you are creating an account, endeavor to use a well-chosen password. You should avoid using any information that others can easily learn about you, such as a family member’s name or birthday, and you can also use special characters in place of letters. You may change the password as often as you wish by using the “Change Password” function in your profile.” We also recommend that you change your password frequently. You can also install and regularly update antivirus and firewall software to protect your computer from external attacks by malicious users. When you are finished with a session on our platform, be sure that you log out and close

the browser window.

MommyandMeCare authorizes only those persons who need to know your personal

information to administer your account, to provide or inform you about products and services, or to maintain, improve or administer our platform to access your personal information.

**12. Use of Cookies**

We use cookies to identify you as a User and make your user experience easier, customize our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember your account log-in information when you visit our website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you have the choice to disable cookies on our website; however, this may limit your ability to use our website.

**13. The Data We Retain**

We will retain your information for as long as needed to provide you with our services, comply with our legal and statutory obligations or verify your information with a financial institution.

We are statutorily obligated to retain the data you provide us with in order to resolve disputes, make refunds, identify fraud and be in compliance with laws and regulatory guidelines applicable to us, and crypto processors.

**14. Problem Signing In**

Our sign-in process is designed to help protect your privacy. If you have trouble signing in to our website, please ensure that you are using your registered e-mail address and/or the correct password. If you are using your registered e-mail address and correct password, and you continue to have trouble signing in to our site, please e-mail us immediately at [hello@evarfinance.com](mailto:hello@evarfinance.com)

**15. Payment Confirmation**

MommyandMeCare sends an e-mail notification confirming a transaction you made to the email address you provided to us when signing up for an EverFinance account. If you receive a confirmation for a transaction you did not initiate or receive, please email us immediately at [hello@MommyandMeCare.com](mailto:hello@MommyandMeCare.com).

**16. Links to Third Party Websites**

i. Our website may contain links to third-party websites or services that are not owned or controlled by us.

ii. have no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.

iii. We strongly advise you to read the terms and conditions and privacy policies of any

third-party web sites or services that you visit.

**17. Limitation to Liability**

We exercise reasonable efforts to safeguard the security and confidentiality of your personal data; however, we will not be liable for unauthorized disclosure of personal data that occurs through no fault of ours.

**18. Changes to this Privacy Policy**

Changes may be made to this Privacy Policy from time. Whenever such changes are made, we will notify you. These changes will take effect immediately after you have been notified.

**19. Contact Us**

If you would like more information or you have any comments or questions on our privacy policy, please contact us at [hello@evarfinance.com](mailto:hello@evarfinance.com). This policy is effective as of 1st of September, 2022
`;
