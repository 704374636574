import styled from 'styled-components';

export const MaxWidthWrapper = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
`;

export const DesktopOnly = styled.span`
  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    display: none;
  }
`;

export const MobileOnly = styled.span`
  @media ${(p) => p.theme.breakpoints.mdAndLarger} {
    display: none;
    color: ${(p) => p.theme.colors.BACKGROUND};
  }
`;

export const Wrapper = styled.footer`
  position: relative;
  line-height: calc(1em + 0.725rem);
`;

export const InnerWrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 32px;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    text-align: left;
    padding-bottom: 32px;
    align-items: flex-start;    
  }
`;

export const LogoWrapper = styled.div`
  margin-right: 32px;

  .logo-link {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    color: ${(p) => p.theme.colors.INFO};

    &:focus {
      outline-offset: 2px;
      outline: 2px auto ${(p) => p.theme.colors.INFO};
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }
  }
`;

export const LogoText = styled.span`
  margin: 0 3px;
  font-size: 24px;
  font-weight: var(--font-weight-regular);
  color: ${(p) => p.theme.colors.TEXT};
`;

export const Right = styled.div`
  display: flex;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    justify-content: flex-start;
  }
`;

export const Column = styled.div`
  margin-left: 96px;
  padding-top: 8px;
  display: flex;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    margin-left: 0;
    width: 100%;
    gap: 100px;
  }
`;

export const Heading = styled.p`
  font-size: 14px;
  opacity: 0.6;
  font-weight: var(--font-weight-light);
  color: var(--color-gray-700);
`;

export const OneColumnChildren = styled.div`
  display: grid;
  grid-gap: 6px;
  padding-top: 12px;
  grid-template-columns: 1fr;
`;
export const ColumnChildren = styled.div`
  display: grid;
  grid-gap: 6px;
  padding-top: 12px;
  margin-left: 96px;
  grid-template-columns: 1fr;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    margin-left: 0;
  }
`;

export const TwoColumnChildren = styled(OneColumnChildren)`
  width: 12.5rem;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 400px) {
    width: 9rem;
    grid-template-columns: 1fr;
  }
`;

export const Top = styled.div``;

export const Thanks = styled.div`
  font-size: 14px;
  margin-top: 8px;
  font-weight: var(--font-weight-regular);
  font-weight: var(--font-weight-medium);
`;

export const Copyright = styled.div`
  opacity: 0.6;
  font-size: 12px;
  color: ${(p) => p.theme.colors.TEXT};
  font-weight: var(--font-weight-regular);
`;

export const MobileCopyright = styled(Copyright)`
  text-align: left;
  padding-top: 48px;
  font-weight: var(--font-weight-regular);
`;

export const FooterLink = styled.ul`
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  color: ${(p) => p.theme.colors.TEXT};
  font-weight: var(--font-weight-light);

  a {
    font-size: 14px;
    text-decoration: none;
    color: ${(p) => p.theme.colors.TEXT};
    font-weight: var(--font-weight-light);
  }
`;

export const Spacer = styled.div`
  min-width: 96px;
  min-height: 96px;
`;

export const BackgroundFlip = styled.div`
  position: absolute;
  width: 100%;
  transition: background 350ms ease 0s;
  background: ${(p) => p.theme.colors.MUTED};
  bottom: 0;
`;
