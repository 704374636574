import React from "react";
import { ThemeProvider } from "./theme";
import { StoreProvider } from "./store";

type ProvidersProps = { children: React.ReactNode };

export const Providers: React.FC<ProvidersProps> = ({ children }) => (
  <StoreProvider>
    <ThemeProvider>{children}</ThemeProvider>
  </StoreProvider>
);
