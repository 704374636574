import styled from 'styled-components';
import { COLOR_SWAP_TRANSITION_DURATION } from '@app/constants';

export const MaxWidthWrapper = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  padding-bottom: 50px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: var(--font-weight-regular);

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Main = styled.main`
  padding: 70px 0 0 0;
  padding-top: 70px;
`;

export const BlogWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`;

export const BlogHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BlogTitle = styled.h1`
  padding-left: 32px;
  padding-bottom: 16px;
  font-size: calc(2.375rem);
  font-weight: var(--font-weight-bold);

  @media (min-width: 1150px) {
    padding-left: 0;
  }
`;

export const BlogCounter = styled.span`
  font-size: 18px;
  padding-right: 32px;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  font-weight: var(--font-weight-regular);

  @media (min-width: 1150px) {
    margin-left: -32px;
    margin-right: -32px;
  }

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    grid-template-columns: 1fr;
  }
`;

export const BlogContent = styled.p`
  font-size: 1rem;
  margin-top: 16px;
`;

export const span = styled.span`
  color: white;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  text-shadow: rgb(0 0 0 / 15%) 1px 1px 1px;
`;

export const DarkHeaderBackground = styled.div`
  top: 0px;
  z-index: 3;
  width: 100%;
  position: sticky;
  height: calc(70px);
  background: ${(p) => p.theme.colors.BACKGROUND};
  transition: ${() =>
    `color ${COLOR_SWAP_TRANSITION_DURATION}ms, background ${COLOR_SWAP_TRANSITION_DURATION}ms`};
`;

export const LightHeaderBackground = styled.div`
  z-index: 4;
  top: 0px;
  width: 100%;
  height: 68px;
  position: sticky;
  background: ${(p) => p.theme.colors.MUTED};
`;

export const FooterSpacer = styled.div`
  width: 100%;
  min-height: 215.44px;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    min-height: 348.81px;
  }
`;

export const Spacer = styled.div<{ size?: number }>`
  min-width: ${({ size }) => size || 45}px;
  min-height: ${({ size }) => size || 45}px;
`;
