import styled from 'styled-components';

export const Wrapper = styled.span`
  box-sizing: content-box;
  margin: 32px auto var(--margin-bottom, 48px);

  /*
  HACK: Next-image does some trickery which undoes the auto-margins.
  I'll need to target it here directly.
*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding: 0;
    border: none;
  }
`;

export const Caption = styled.span`
  display: block;
  padding-top: 12px;
  font-size: 14px;
  text-align: center;
`;

export const ImageElem = styled.img<{ isLoading: boolean }>`
  width: 100%;
  margin: auto; /* In case width is overridden, center it */
  display: block;
  min-height: 400px;
  max-height: 600px;
  border-radius: 3px;
  object-fit: contain;
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
`;

export const ImageSkeleton = styled.div<{ isLoading: boolean }>`
  width: 100%;
  height: 400px;
  border-radius: 3px;
  background-color: red;
  animation: skeleton-loading 1s linear infinite alternate;
  display: ${({ isLoading }) => (!isLoading ? 'none' : 'block')};

  @keyframes skeleton-loading {
    0% {
      background-color: hsl(200, 20%, 80%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
`;
