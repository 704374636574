import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
// import {  animateScroll as scroll } from "react-scroll";
import { useLocation, useNavigate } from 'react-router-dom';

import { Layout } from '@app/components/Layout';
import { useSelector, useDispatch } from '@app/providers/store';
import { getSingleArticle } from '@app/providers/store/articles/actions';
import { ArticleTableOfContent } from '@app/providers/store/articles/models';
import { NewsLetterSignUp } from '@app/components/newsletter/NewsletterSignUp';
import { formatDate, renderMdxComponents, getMdxTableOfContent } from '@app/helpers';

import {
  Next,
  Main,
  Aside,
  Title,
  Spacer,
  Target,
  Heading,
  BodyBack,
  SubTitle,
  DateTitle,
  DatePrefix,
  HeroSection,
  DateWrapper,
  MainWrapper,
  HeroWrapper,
  ContentLink,
  FooterSpacer,
  LayoutWrapper,
  ContentFooter,
  ArticleContent,
  TableOfContent,
  MaxWidthWrapper,
  DarkHeaderBackground,
  LightHeaderBackground,
  SneakyHeaderBackground,
} from './details.styles';
import { ArticlesDetails } from '../article_details';

export const Details: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { selectedArticle, isLoading } = useSelector(({ articles }) => articles);
  const [tableOfContent, setTableOfContent] = useState<ArticleTableOfContent[]>([]);

  useEffect(() => {
    const slug = pathname.trim().replace('/', '');

    if (selectedArticle) {
      const article = selectedArticle.slug === slug;
      if (article) {
        const tableOfContent = getMdxTableOfContent(selectedArticle.markdown);
        setTableOfContent(tableOfContent);
      } else {
        navigate('/not-found');
      }
    } else {
      getSingleArticle(slug, (error) => {
        if (error) navigate('/not-found');
      })(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, selectedArticle]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const articleTableOfContent = Object.values(tableOfContent);

  return (
    <Layout isLoading={isLoading}>
      {selectedArticle ? (
        <Next>
          <BodyBack />
          <LayoutWrapper />
          <HeroWrapper>
            <LightHeaderBackground />
            <SneakyHeaderBackground />
            <HeroSection>
              <MaxWidthWrapper>
                <Title>{selectedArticle?.title}</Title>
                {selectedArticle?.subtitle && (
                  <SubTitle className="small-heading">{selectedArticle?.subtitle}</SubTitle>
                )}
              </MaxWidthWrapper>
            </HeroSection>
          </HeroWrapper>
          <DarkHeaderBackground />
          <LayoutWrapper>
            <Main>
              <Target />
              <MainWrapper className="main-section">
                {articleTableOfContent.length ? (
                  <Aside>
                    <TableOfContent>
                      <Heading className="tableOfContent__heading">Table of Contents</Heading>

                      {articleTableOfContent.map(({ href, title }) => (
                        <ContentLink
                          to={href}
                          spy={true}
                          smooth={true}
                          offset={-80}
                          duration={500}
                          key={href}
                          activeClass="table_of_content_link"
                        >
                          {title}
                        </ContentLink>
                      ))}
                    </TableOfContent>
                  </Aside>
                ) : null}

                <ArticleContent>
                  {selectedArticle.markdown && (
                    <ReactMarkdown
                      //@ts-ignore
                      components={renderMdxComponents({ tableOfContent })}
                    >
                      {selectedArticle.markdown}
                    </ReactMarkdown>
                  )}
                  <Spacer />
                  <ContentFooter>
                    <DateWrapper>
                      <DatePrefix>Last Updated</DatePrefix>
                      <DateTitle>{formatDate(selectedArticle?.updated_at)}</DateTitle>
                    </DateWrapper>
                  </ContentFooter>
                  <NewsLetterSignUp />
                  <Spacer />
                  <Title style={{ fontSize: '18px' }}>Latest Articles</Title>
                  <ArticlesDetails />
                </ArticleContent>
              </MainWrapper>
            </Main>
          </LayoutWrapper>

          <Spacer />
          <Spacer />
          <Spacer />
          <FooterSpacer />
        </Next>
      ) : null}
    </Layout>
  );
};
