import styled from 'styled-components';

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: medium;
  color: var(--color-gray-1000);

  &:hover {
    color: ${(p) => p.theme.colors.PRIMARY};
  }
`;

export const Wrapper = styled.div`
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: var(--font-weight-regular);
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding-left: 16px;
    padding-right: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
