import React from 'react';
import styled, { css } from 'styled-components';
import { ArticleTableOfContent } from '../../providers/store/articles/models';

export type BaseStrongProps = {
  children: string[];
  tableOfContent?: ArticleTableOfContent[];
};

export const Strong: React.FC<BaseStrongProps> = ({ children, tableOfContent }) => {
  const content = tableOfContent?.find(({ title }) => title === children[0]);

  return (
    <BaseStrong id={content?.href} isTableOfContent={!!content?.href}>
      {children}
    </BaseStrong>
  );
};

export const BaseStrong = styled.strong<{ isTableOfContent: boolean }>`
  font-weight: var(--font-weight-bold);

  ${({ isTableOfContent }) =>
    isTableOfContent &&
    css`
      font-size: calc(22 / 16 * 1.2rem);
      color: ${(p) => p.theme.colors.TERTIARY};
    `}
`;
