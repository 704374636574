import styled from 'styled-components';

export const LogoWrapper = styled.div`
  margin-right: 32px;

  .logo-link {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    color: ${(p) => p.theme.colors.PRIMARY};

    &:focus {
      outline-offset: 2px;
      outline: 2px auto ${(p) => p.theme.colors.INFO};
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const LogoText = styled.span`
  margin: 0 3px;
  font-size: 24px;
  color: ${(p) => p.theme.colors.TEXT};
  font-weight: var(--font-weight-regular);
  @media (max-width: 390px) {
    font-size: 16px;
  }
`;

export const Image = styled.img`
  width: 30px;
  height: 26px;
`;
