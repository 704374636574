import { ArticleTableOfContent } from '../providers/store/articles/models';

const END_SEARCH_PATTERN = '**\n';
const START_SEARCH_PATTERN = '\n**';

export const getMdxTableOfContent = (mdx: string) => {
  let startIndex: number | null = null;
  const table: ArticleTableOfContent[] = [];

  for (let index = 0; index < mdx.length; index++) {
    const startCheck = mdx.substring(index, START_SEARCH_PATTERN.length + index);

    const endCheck = mdx.substring(index, END_SEARCH_PATTERN.length + index);

    if (startCheck === START_SEARCH_PATTERN) {
      startIndex = index;
    }

    if (endCheck === END_SEARCH_PATTERN && startIndex) {
      const phrase = mdx.substring(startIndex, index + END_SEARCH_PATTERN.length);

      const title = phrase.trim().replace(/[(**)]/g, '');
      const href = title.toLowerCase().split(' ').join('-');

      table.push({ title, href });
      console.log(href)
    }
  }

  return table;
};
