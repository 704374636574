import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Spinner } from './Spinner/Spinner';

type LayoutProps = {
  isLoading?: boolean;
  children: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ isLoading = false, children }) => {
  return (
    <Container isLoading={isLoading}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          {<Header />}
          {children}
          {<Footer />}
        </Fragment>
      )}
    </Container>
  );
};

export const Container = styled.div<{ isLoading: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;
