import { createContext, Dispatch } from "react";

import { initialSettingsState } from "./settings/reducers";
import { ISettingsState, SettingsActionTypes } from "./settings/models";

import { initialArticlesState } from "./articles/reducers";
import { IArticlesState, ArticlesActionTypes } from "./articles/models";

// We define our type for the context dispatch types right here
export type DispatchTypes = SettingsActionTypes | ArticlesActionTypes;

// We define our type for the context properties right here
export interface ContextState {
  settings: ISettingsState;
  articles: IArticlesState;
}

// We define our type for the context properties right here
export interface ContextProps {
  store: ContextState;
  dispatch: Dispatch<DispatchTypes>;
}

const StoreContext = createContext<ContextProps>({
  dispatch: () => {},
  store: { settings: initialSettingsState, articles: initialArticlesState },
});

export default StoreContext;
