import React from 'react';

import { BREAKPOINT_SIZES } from '@app/providers/theme/style';
import useWindowDimensions from '@app/hooks/use.window.dimension.hook';

export default function useDesktopState(initialVal: any) {
  const windowDimensions = useWindowDimensions();

  const [value, setValue] = React.useState(initialVal);

  const setValueOnDesktop = (args: any) => {
    if (windowDimensions.width) {
      if (windowDimensions.width >= BREAKPOINT_SIZES.md) {
        setValue(args);
      }
    }
  };

  return [value, setValueOnDesktop];
}
