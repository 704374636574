import { ArticlesActionTypes, IArticlesState } from '../models';
import { ARTICLE_SUCCESS, ARTICLES_FAILURE, ARTICLES_REQUEST, ARTICLES_SUCCESS } from '../types';

export const initialArticlesState: IArticlesState = {
  data: [],
  error: null,
  meta_data: null,
  isLoading: false,
  selectedArticle: null,
  selectedArticleTableOfContent: null,
};

export const articlesReducer = (
  state: IArticlesState = initialArticlesState,
  action: ArticlesActionTypes
): IArticlesState => {
  switch (action.type) {
    case ARTICLES_REQUEST:
      return { ...state, isLoading: true };

    case ARTICLES_SUCCESS:
      const { docs, ...meta_data } = action.payload;

      return { ...state, meta_data, data: docs, isLoading: false };

    case ARTICLE_SUCCESS:
      return { ...state, isLoading: false, selectedArticle: action.payload };

    case ARTICLES_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};
