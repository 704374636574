import useSoundBase from "use-sound";
import { HookOptions } from "use-sound/dist/types";

import { useSelector } from "../providers/store";
// import { SET_SOUND} from '../providers/store/settings/actions';

export default function useSound(url: string, delegated: HookOptions) {
  const { soundEnabled } = useSelector(({ settings }) => settings);

  return useSoundBase(url, { ...delegated, soundEnabled });
}
