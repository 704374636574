import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../logo/Logo';

import {
  Top,
  Left,
  Right,
  Thanks,
  Column,
  Heading,
  Wrapper,
  Copyright,
  FooterLink,
  MobileOnly,
  DesktopOnly,
  InnerWrapper,
  BackgroundFlip,
  ColumnChildren,
  MobileCopyright,
  OneColumnChildren,
} from './Footer.styles';

export const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <BackgroundFlip
      style={{
        backgroundColor: pathname === '/' ? 'var(--color-background)' : '',
      }}
    >
      <Wrapper style={{ backgroundColor: pathname === '/' ? 'initial' : 'inherit' }}>
        <InnerWrapper>
          <Left>
            <Top>
              <Logo />
              <DesktopOnly>
                <Thanks>Thanks for reading!</Thanks>
              </DesktopOnly>
            </Top>
            <DesktopOnly>
              <Copyright>© 2023 mommyandmecare. All Rights Reserved.</Copyright>
            </DesktopOnly>
          </Left>
          <Right>
            <Column>
              <ColumnChildren>
                <Heading>Socials</Heading>
                <OneColumnChildren>
                  <FooterLink>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/evarfinance"
                    >
                      Facebook
                    </a>
                  </FooterLink>
                  <FooterLink>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/evarfinance"
                    >
                      Instagram
                    </a>
                  </FooterLink>
                  <FooterLink>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/evarfinance"
                    >
                      Twitter
                    </a>
                  </FooterLink>
                  <FooterLink>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/evarfinance"
                    >
                      Tiktok
                    </a>
                  </FooterLink>
                </OneColumnChildren>
              </ColumnChildren>
              <ColumnChildren>
                <Heading>Links</Heading>
                <OneColumnChildren>
                  <FooterLink>
                    <a target="_blank" rel="noopener noreferrer" href="mailto:evarpay@gmail.com">
                      Contact
                    </a>
                  </FooterLink>
                  <FooterLink onClick={() => navigate('/terms-of-use')}>Terms of Use</FooterLink>
                  <FooterLink onClick={() => navigate('/privacy-policy')}>
                    Privacy Policy
                  </FooterLink>
                </OneColumnChildren>
              </ColumnChildren>
            </Column>
          </Right>
          <MobileOnly>
            <MobileCopyright>© 2023 mommyandmecare. All Rights Reserved.</MobileCopyright>
          </MobileOnly>
        </InnerWrapper>
      </Wrapper>
    </BackgroundFlip>
  );
};
