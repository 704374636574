import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { useDispatch } from '@app/providers/store';
import { Article } from '@app/providers/store/articles/models';
import useDesktopState from '@app/hooks/use-desktop-state.hook';
import { articleSuccess } from '@app/providers/store/articles/actions';

import { Title, Wrapper } from './Article_detail.style';

export const ArticleDetailCard: React.FC<Article> = (article) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isHoveringOverCard, setIsHoveringOverCard] = useDesktopState(false);

  const handleNavigation: React.FormEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    navigate(article.slug);
    dispatch(articleSuccess(article));
  };

  return (
    <Wrapper
      onMouseEnter={() => setIsHoveringOverCard(true)}
      onMouseLeave={() => setIsHoveringOverCard(false)}
    >
      <NavLink to="/" onClick={handleNavigation}>
        <Title>{article.title}</Title>
      </NavLink>
    </Wrapper>
  );
};
