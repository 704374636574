import React, { useEffect } from 'react';

import { useSelector, useDispatch } from '@app/providers/store';
import { getLatestArticles } from '@app/providers/store/articles/actions';

import { Spacer, BlogWrapper, ContentWrapper } from './articles_detail.style';
import { ArticleDetailCard } from '@app/components/article/Article_detail';

export const ArticlesDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { data: articles } = useSelector(({ articles }) => articles);

  useEffect(() => {
    if (!articles?.length) {
      getLatestArticles(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles.length]);

  return (
    <div>
      <BlogWrapper>
        <ContentWrapper>
          <ArticleDetailCard {...articles[0]} />
          <ArticleDetailCard {...articles[1]} />
          <ArticleDetailCard {...articles[0]} />
          <ArticleDetailCard {...articles[1]} />
          <ArticleDetailCard {...articles[0]} />
          <ArticleDetailCard {...articles[1]} />
        </ContentWrapper>
        <Spacer />
      </BlogWrapper>
      <Spacer />
    </div>
  );
};
