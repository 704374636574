import styled from "styled-components";

export const LayoutWrapper = styled.header`
  top: 0px;
  z-index: 4;
  display: flex;
  position: sticky;
  height: calc(70px);
  flex-direction: column;
  background: inherit;
`;

export const HeaderWrapper = styled.div`
  z-index: 3;
  height: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  justify-content: space-between;
  background: inherit;
`;

export const MaxWidthWrapper = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
`;

export const NavLinkWrapper = styled.div`
  display: flex;

  @media ${(p) => p.theme.breakpoints.mobile} {
    display: none;
  }
`;

export const NavList = styled.li`
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  list-style: none;
  font-weight: var(--font-weight-regular);

  &:active {
    font-weight: var(--font-weight-bold);
  }
`;

export const NavLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const RightDesktop = styled.div`
  @media ${(p) => p.theme.breakpoints.mobile} {
    display: none;
  }
`;

export const RightMobile = styled.div`
  display: flex;
  @media ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  gap: 20px;
  display: flex;
  transition: opacity 250ms;
`;

export const Btn = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  display: block;
  cursor: pointer;
  text-align: left;
  background: transparent;

  &:focus {
    outline: 2px auto ${(p) => p.theme.colors.PRIMARY};
    outline-offset: 2px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
`;

export const Button = styled(Btn)`
  width: 40px;
  height: 32px;
  opacity: 0.7;
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media (min-width: 1044px) {
    &:hover {
      opacity: 1;
    }
  }

  svg,
  path,
  .moon-sun {
    color: ${(p) => p.theme.colors.TEXT};
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
`;

export const RssWrapper = styled(ToggleWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 840px) {
    display: none;
  }
`;

export const Svg = styled.svg`
  display: block;
  color: ${(p) => p.theme.colors.TEXT};
  stroke: ${(p) => p.theme.colors.TEXT};
`;

export const Navlist = styled.ul`
  display: flex;
  list-style: none;
`;

export const Listitem = styled.li`
  margin: 10px;
  font-size: 12px;
  list-style: none;
  font-weight: 500;
  cursor: pointer;

  &:active {
    font-weight: var(--font-weight-bold);
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;

  .nav__link {
    padding: 10px;
    font-size: 1rem;
    position: relative;
    text-decoration: none;
    color: ${(p) => p.theme.colors.TEXT};
    font-weight: var(--font-weight-medium);
  }
`;
