import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
    clientWidth: 0,
  });

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const handleResize = throttle(() => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        //@ts-ignore
        clientWidth: document.documentElement.clientWidth,
      });
    }, 250);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // For the very first render, for whatever reason, the width of the scrollbars
  // is sometimes not taken into account, so we need to wait until after
  // the first render to set it.
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (windowDimensions.clientWidth !== document.documentElement.clientWidth) {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        //@ts-ignore
        clientWidth: document.documentElement.clientWidth,
      });
    }
    // The whole point is to fix an irregularity on mount, so we only want to
    // run this effect on mount.
  }, [windowDimensions.clientWidth]);

  return windowDimensions;
};

export default useWindowDimensions;
