import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.TEXT};
  font-weight: var(--font-weight-medium);
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 8px 0px 12px;
  background: transparent;
  color: ${(p) => p.theme.colors.TEXT};

  &:disabled {
    opacity: 0.5;
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.GRAY_300};
  }
`;

export const Border = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  opacity: 0.6;
  border-radius: 2px;
  position: absolute;
  pointer-events: none;
  background: ${(p) => p.theme.colors.TEXT};

  @media (prefers-reduced-motion: no-preference) {
    transition: all 200ms ease-out;
  }
`;
