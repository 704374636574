import { Em } from '../components/Em';
import Blockquote from '../components/BlockQuote';
import { PostImage } from '../components/PostImage';
import { Paragraph } from '../components/paragraph';
import { H1, H2, H3, H4 } from '../components/Heading';
import Strikethrough from '../components/Strikethrough';
import { BaseStrongProps, Strong } from '../components/Strong';

export const renderMdxComponents = (props: any) => ({
  i: Em,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  em: Em,
  p: Paragraph,
  img: PostImage,
  strike: Strikethrough,
  blockquote: Blockquote,
  strong: (p: BaseStrongProps) => Strong({ ...p, ...props }),
});
