import { Portal } from 'react-portal';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Policy } from './pages/policy';
import { Details } from './pages/details';
import { TermsOfUse } from './pages/terms';
import { Providers } from '@app/providers';
import { NotFound } from './pages/notFound';
import { Articles } from './pages/articles';
import { Toaster } from './components/Toaster';

export default function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Articles />} />
          <Route path=":slug" element={<Details />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <Portal>
        <Toaster />
      </Portal>
    </Providers>
  );
}
