import styled from 'styled-components';
import { COLOR_SWAP_TRANSITION_DURATION } from '@app/constants';

export const Title = styled.h3`
  margin-bottom: 10px;
  font-size: calc(1.375rem);
  color: var(--color-gray-1000);

  &:hover {
    color: ${(p) => p.theme.colors.PRIMARY};
  }
`;

export const CardSpace = styled.div`
  min-width: 16px;
  min-height: 16px;
`;

export const Content = styled.p`
  font-size: 1rem;
  margin-top: 16px;
  font-weight: var(--font-weight-light);
`;

export const Wrapper = styled.div`
  padding: 32px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: var(--font-weight-regular);
  background: ${(p) => p.theme.colors.SUBTLE_FLOATING};
  /* transition: background ${COLOR_SWAP_TRANSITION_DURATION}ms; */

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding-left: 16px;
    padding-right: 16px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const SubTitle = styled.h4`
  color: #6c7693;
  font-size: calc(1.0625rem);
  font-weight: var(--font-weight-medium);
`;
