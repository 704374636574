import { animated } from 'react-spring';
import styled from 'styled-components';
import { Button } from '../Header/Header.styles';

export const IconWrapper = styled(Button)`
  opacity: 0.7;
  width: 40px;
  height: 32px;
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media  ${(p) => p.theme.breakpoints.mdAndSmaller}{
    &:hover {
      opacity: 1;
    }
  }
`;

export const MoonOrSun = styled(animated.svg)`
  position: relative;
  overflow: visible;
`;

