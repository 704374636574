import styled from "styled-components";

export const Loader = styled.div<{ size: number }>`
  margin: 0 2rem;
  border-radius: 50%;
  animation-duration: 1s;
  background: transparent;
  width: ${({ size }) => size}px;
  animation-name: rotate-s-loader;
  height: ${({ size }) => size}px;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 5px solid
    ${({ theme }) =>
      theme.isDarkMode
        ? theme.colors.HOMEPAGE_BACKGROUND
        : theme.colors.GRAY_400};
  border-left-color: ${({ theme }) =>
    theme.isDarkMode ? theme.colors.TEXT : theme.colors.GRAY_1000};

  @keyframes rotate-s-loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
