// import original module declarations
import 'styled-components';

export const BREAKPOINT_SIZES = {
  xs: 320,
  sm: 563,
  md: 725,
  lg: 1024,
  xl: 1440,
};

export const BREAK_POINTS = {
  xs: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  sm: `(min-width: ${BREAKPOINT_SIZES.xs}px and max-width: ${BREAKPOINT_SIZES.sm}px)`,
  md: `(min-width: ${BREAKPOINT_SIZES.sm}px and max-width: ${BREAKPOINT_SIZES.md}px)`,
  lg: `(min-width: ${BREAKPOINT_SIZES.md}px and max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xl: `(min-width: ${BREAKPOINT_SIZES.lg}px and max-width: ${BREAKPOINT_SIZES.xl}px)`,
  xsAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xs}px)`,
  smAndSmaller: `(max-width: ${BREAKPOINT_SIZES.sm}px)`,
  mdAndSmaller: `(max-width: ${BREAKPOINT_SIZES.md}px)`,
  lgAndSmaller: `(max-width: ${BREAKPOINT_SIZES.lg}px)`,
  xlAndSmaller: `(max-width: ${BREAKPOINT_SIZES.xl}px)`,
  xsAndLarger: `(min-width: ${BREAKPOINT_SIZES.xs + 1}px)`,
  smAndLarger: `(min-width: ${BREAKPOINT_SIZES.sm + 1}px)`,
  mdAndLarger: `(min-width: ${BREAKPOINT_SIZES.md + 1}px)`,
  lgAndLarger: `(min-width: ${BREAKPOINT_SIZES.lg + 1}px)`,
  xlAndLarger: `(min-width: ${BREAKPOINT_SIZES.xl + 1}px)`,
  mobile: `(max-width: ${BREAKPOINT_SIZES.md}px)`,
  desktop: `(min-width: ${BREAKPOINT_SIZES.md + 1}px)`,
};

// All app colors
export enum LIGHT_MODE_COLORS {
  TEXT = 'hsl(222deg, 22%, 5%)',
  BACKGROUND = 'hsl(225deg, 30%, 95%)',
  DETAILS_BACKGROUND = 'hsl(0deg, 00%, 100%)',
  BLURRED_BACKGROUND = 'hsla(0deg, 0%, 100%, 0.85)',
  PRIMARY = 'hsl(245deg, 100%, 60%)',
  SECONDARY = 'hsl(333deg, 100%, 45%)',
  TERTIARY = 'hsl(255deg, 85%, 30%)',
  DECORATIVE = 'hsl(200deg, 75%, 65%)',
  MUTED = 'hsl(210deg, 55%, 92%)',
  MUTED_BACKGROUND = 'hsla(210deg, 55%, 92%, 0.85)',
  ERROR = 'hsl(340deg, 95%, 50%)',
  ERROR_BACKGROUND = 'hsla(340deg, 95%, 43%, 0.1)',
  SUCCESS = 'hsl(160deg, 100%, 40%)',
  SUCCESS_BACKGROUND = 'hsla(160deg, 100%, 40%, 0.1)',
  ALERT = 'hsl(37deg, 100%, 50%)',
  ALERT_BACKGROUND = 'hsla(52deg, 100%, 50%, 0.25)',
  SUBTLE_BACKGROUND = 'hsl(225deg, 25%, 95%)',
  SUBTLE_FLOATING = 'hsl(0deg, 0%, 100%)',
  HOMEPAGE_LIGHT = 'hsl(204deg, 67%, 85%)',
  HOMEPAGE_DARK = 'hsl(202deg, 71%, 90%)',
  HOMEPAGE_BACKGROUND = 'hsl(204deg, 67%, 85%)',
  INFO = 'hsl(245deg, 100%, 60%)',
  GRAY_100 = 'hsl(225deg, 25%, 95%)',
  GRAY_200 = 'hsl(225deg, 16%, 90%)',
  GRAY_300 = 'hsl(225deg, 8%, 80%)',
  GRAY_400 = 'hsl(225deg, 8%, 70%)',
  GRAY_500 = 'hsl(225deg, 7%, 60%)',
  GRAY_600 = 'hsl(225deg, 15%, 50%)',
  GRAY_700 = 'hsl(225deg, 12%, 40%)',
  GRAY_900 = 'hsl(225deg, 25%, 20%)',
  GRAY_1000 = 'hsl(225deg, 15%, 15%)',
}

export enum DARK_MODE_COLORS {
  TEXT = 'hsl(0deg, 0%, 100%)',
  BACKGROUND = 'hsl(210deg, 30%, 8%)',
  DETAILS_BACKGROUND = 'hsl(210deg, 30%, 8%)',
  BLURRED_BACKGROUND = 'hsla(210deg, 30%, 8%, 0.85)',
  PRIMARY = 'hsl(230deg, 100%, 67%)',
  SECONDARY = 'hsl(333deg, 100%, 52%)',
  TERTIARY = 'hsl(53deg, 100%, 50%)',
  DECORATIVE = 'hsl(200deg, 50%, 60%)',
  MUTED = 'hsl(210deg, 38%, 15%)',
  MUTED_BACKGROUND = 'hsla(210deg, 38%, 15%, 0.85)',
  ERROR = 'hsl(340deg, 95%, 60%)',
  ERROR_BACKGROUND = 'hsla(340deg, 95%, 43%, 0.1)',
  SUCCESS = 'hsl(160deg, 100%, 40%)',
  SUCCESS_BACKGROUND = 'hsla(160deg, 100%, 40%, 0.1)',
  ALERT = 'hsl(30deg, 100%, 50%)',
  ALERT_BACKGROUND = 'hsla(38deg, 100%, 50%, 0.1)',
  SUBTLE_BACKGROUND = 'hsl(210deg, 30%, 8%)',
  SUBTLE_FLOATING = 'hsl(210deg, 22%, 15%)',
  HOMEPAGE_LIGHT = 'hsla(200deg, 100%, 85%, 0)',
  HOMEPAGE_DARK = 'hsla(200deg, 100%, 85%, 0.1)',
  HOMEPAGE_BACKGROUND = 'hsl(210deg, 30%, 8%)',
  INFO = 'hsl(230deg, 100%, 67%)',
  GRAY_100 = 'hsl(210deg, 15%, 20%)',
  GRAY_200 = 'hsl(210deg, 15%, 25%)',
  GRAY_300 = 'hsl(210deg, 10%, 40%)',
  GRAY_400 = 'hsl(210deg, 9%, 45%)',
  GRAY_500 = 'hsl(210deg, 8%, 50%)',
  GRAY_600 = 'hsl(210deg, 12%, 55%)',
  GRAY_700 = 'hsl(210deg, 14%, 66%)',
  GRAY_900 = 'hsl(210deg, 25%, 88%)',
  GRAY_1000 = 'hsl(210deg, 25%, 96%)',
}

export const COLOR_SWAP_TRANSITION_DURATION = 350;

declare module 'styled-components' {
  export interface DefaultTheme {
    // App dark mode condition
    isDarkMode: boolean;
    // Toggle App theme
    toggleTheme: () => void;
    // App break points
    breakpoints: typeof BREAK_POINTS;
    // All Global App Colors typings
    colors: typeof DARK_MODE_COLORS | typeof LIGHT_MODE_COLORS;
    // Toggle App theme
    hexToRGB: (color: string, opacity: number) => string;
  }
}
