import { Button } from '../Header/Header.styles';
import styled, { keyframes } from 'styled-components';

export const IconWrapper = styled(Button)`
  opacity: 0.7;
  width: 40px;
  height: 32px;
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

  @media ${(p) => p.theme.breakpoints.mdAndSmaller} {
    &:hover {
      opacity: 1;
    }
  }
`;

export const Path = styled.path`
  stroke: ${(p) => p.theme.colors.TEXT};

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 200ms;
  }
`;

export const FilledPath = styled(Path)<{ shouldWiggle: boolean }>`
  transform-origin: 30% center;
  fill: ${(p) => p.theme.colors.TEXT};

  @media (prefers-reduced-motion: no-preference) {
    animation: ${(p) => (p.shouldWiggle ? wiggle : null)} 350ms;
  }
`;

export const wiggle = keyframes`
  0% {
    transform: rotate(0deg)
  }
  25% {
    transform: rotate(15deg)
  }
  50% {
    transform: rotate(-13deg)
  }
  75% {
    transform: rotate(12deg)
  }
  100% {
    transform: rotate(0deg)
  }
`;
