export const TERMS_OF_USE = `
## **MommyandMeCare Terms of Use**

Our Terms of Use are effective as of ………………………2022.

By signing up for an account on our platform, or by using any of our Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Use”).

As used in these Terms of Use, “we”, “us” and MommyandMeCare refers to our Company, MommyandMeCare Inc.

The services offered by MommyandMeCare under this Terms of Use include digital payment services using cryptocurrency as the payment method (“Service” or “Services”). Any new feature or tool that is added to the current Services shall also be construed as “Service” or “Services” and shall be subject to this Terms of Use.

You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Use, including our Privacy Policy before you may sign up for an account on any of our platforms and/or use any of our Services. Should you desire to use or continue to use our website, Applications or solutions (“Platform”) and/or Services, with or without signing up for an account, you will be deemed to have agreed to be bound by this Terms of Use and this Terms of Use shall be applicable to you.

This site may include commodities, content or services provided by third parties, including materials provided by other users, merchants, bloggers and third-party licensors, syndicators, aggregators, crypto currency exchange platforms and/or reporting services (“Third-Party Providers”). All statements and/or opinions expressed in these materials, and responses to questions and other content or services by the Third-Party Providers, other than the content or services provided by MommyandMeCare, are solely the opinions and the responsibility of the Third-Party Providers providing such content, services or materials, and do not reflect the opinion or position of MommyandMeCare. We are therefore not responsible, or liable to you or any third party, for the services or content or the accuracy of any materials provided by any Third-Party Providers.

**OUR SERVICE**

MommyandMeCare is a technology Company that enables the use of crypto currency as the paym44ent model for transactions made by its users.

**PAYMENT**

MommyandMeCare shall not be liable or responsible for any actions or inactions of the User and hereby disclaims any and all responsibility and liability in that regard. Please note that we will not be liable for any actions, loss or damage that may arise from your use of the integrated payment service, as the same is services by a Third-Party Provider. Kindly refer to the terms of use and/or other legal documents of the Third-Party Providers to understand your legal rights and obligations in the use of the services and content or materials provided by Third-Party Providers.

**UPDATES, MODIFICATIONS & AMENDMENTS**

We may need to update, modify or amend these Terms of Use as our technology evolves and as required by law. We reserve the right to make changes to the same from time to time and notify Users of material changes. You also agree to receive notices from us electronically. The Terms of Use will apply from the effective date provided at the top-left corner of this Notice. We advise that you check this page often, referring to the date of the last modification on this page. If a User objects to any of the changes to this Terms of Use, the User must cease using this Website, our Mobile Applications and affiliated Services via any of our platforms or terminate their account in the event an account has been created.

**ELIGIBILITY AND ACCOUNT REGISTRATION**

You must be at least 18 years or the age of majority in your State of residence, and you may register for an account (your “Account”) to gain full access to use the Service. Kindly note that to access our Services without registering as a User, you can only make payments via payment links and QR codes.

**a. Registering, Opening, and Using an Account**

To register, open, use, or upgrade an Account, MommyandMeCare may require that you submit certain information, including but not limited to your name, business name, email address, text-enabled mobile phone number, street address, zip code etc. You represent and warrant that you own the email or mobile phone number you register with and all information entered or collected in the course of creating your Account and any information you subsequently add or update from your settings (“Account Information”) is true and accurate, and you agree to keep your Account Information current.

**b. Validating and Authenticating Your Identity**

Kindly note that Users will be required to validate their Accounts. You hereby authorize us, directly or through the use of third parties, to make any inquiries we consider necessary to validate or authenticate

your identity and Account Information. This may include asking you for further information or documentation about your Account usage or identity, requiring you to take steps to confirm ownership of your email address, mobile phone number or financial instruments, or verifying your information against third-party databases or through other sources.

In connection with your use of our Service, you may not refuse to (a) cooperate in an investigation concerning activity that potentially violates the law or the Terms, (b) provide confirmation of your identity, or © provide confirmation of any information you provide us. If you decline to provide us with the information that we request or if we cannot verify your identity to our satisfaction (in our sole judgment and discretion) for any reason, then you will not be able to use our platform or Service. In addition, we reserve the right to suspend or terminate the Account of anyone who provides inaccurate, untrue, or incomplete information. The first time you access your Account using a new device, we may require you to authenticate your Account with additional information. If your Account cannot be verified, you may be required to set up a new Account.

**CONFIDENTIALITY AND SECURITY**

We take reasonable precautions to protect your confidential information. For example, we encrypt databases and the information we transmit. We also conduct regular security assessments, and penetration tests and install security patches on our systems in line with global industry security standard practices. We expect that you will take the necessary precautions to secure MommyandMeCare confidential information too.

**Keeping it confidential: **While using our services, you may share confidential information with us, and you may become aware of confidential information about us. Your good self and our Company both agree to take reasonable steps to protect the other party’s confidential information from being

accessed by unauthorized individuals. However, confidential information may be shared with legal or regulatory authorities if required to do so.

**Security**: We take security seriously and you should too.

**Security safeguards**: We have invested in technical, physical and administrative safeguards to do our part to help keep your data safe and secure. While we have taken steps to help protect your data, no method of electronic storage is completely secure, and we cannot guarantee absolute security. We will notify you if there appears to be unauthorized access to your account and we may also restrict access to certain parts of our services until you verify that access was by an authorized user.

**Account security features**: We may introduce security features to make your account more secure, such as two-step authentication, biometrics, transaction PIN, database encryption, use of security PIN and a strong hashing algorithm password. Depending on where you are in the world or what services you are using, we may require you to adopt some of these features. Where we make the use of security features optional, you are responsible (meaning we are not liable) for any consequences of not using those features. We strongly encourage you to use all optional security features.

**Playing your part to secure your data: **You have an important part to play by keeping your login details secure, not allowing unauthorized access or disclosure to third parties, and making sure you have strong security on your own systems. If you realize that there has been or threatened to be any unauthorized use of your data, we will advise you to immediately change your private credentials and subsequently contact support via or call, should you have further concerns. You also agree not to use free-form fields in any of our platforms or services to store data.

**Third-party products:** Along with the use of our services, you may use data, and services from other merchants (third-party products), for example, those we make available on our Website: [https://ever.finance](https://ever.finance/) or any of our Platforms. Any merchant or third party providing a third-party product is a provider and is independent of us, so be aware that a provider may also charge you fees in addition to what you pay us subject to the third parties. Kindly note that as you make payments via our Website, Application or affiliated platforms, the payment integrator will be responsible for securing

your confidential wallet details and other financial information.

**Third-party terms and descriptions: **Third-party products are subject to terms and conditions and privacy notices set by their providers. These include how the provider will use the data that you make available to them. Be sure to read and make sure you agree to their terms and conditions and understand their approach to personal and non-personal data before you connect to them. The descriptions of third-party products that we publish, and any associated links, have been provided to us by the providers. While we make reasonable efforts to check the accuracy of the descriptions, the providers are solely responsible for any representations contained in those descriptions. We do not endorse or assume any responsibility for third-party products.

**Maintenance and downtime:** We really try to minimize any downtime, but sometimes it is necessary so we can keep our services updated and secure. You also may have occasional access issues and may experience data loss, so backing up your data is important.

**Availability**: We strive to maintain the availability of our services, and provide email support, 24 hours a day. Occasionally, we need to perform maintenance on our services, and this may require a period of downtime. We try to minimize any such downtime. Where planned maintenance is being undertaken, we will attempt to notify you in advance but can’t guarantee it.

**Access issues**: You know how the internet works — occasionally you might not be able to access our services and your data. This might happen for any number of reasons, at any time.

**Data loss**: Data loss is an unavoidable risk when using any technology. You are responsible for maintaining copies of your data entered into our Platform.

**No compensation:** Whatever the cause of any downtime, access issues or data loss, your only recourse is to discontinue using our services.

**Problems and support:** If you have a problem, we have excellent support articles. You can find more information about online support for our services via hello@evarfinance.com

**TEXT MESSAGES AND OTHER COMMUNICATIONS**

Here is where we describe your consent to receive texts from us and you also consent to us sending messages to your email. You can opt-out of communications in ways that are specific to that communication method, such as by unsubscribing from emails. By providing us with a mobile number, either when you sign up for an Account or update the contact information associated with your Account, you consent to receive text (SMS) messages and push notifications from us. Such communications may include but are not limited to requests for secondary authentication, receipts, reminders, notifications regarding updates to your account or account support, and marketing or promotional communications. You acknowledge that you are not required to consent to receive promotional texts or calls as a condition of using the Services. Call and text message communications may be generated by automatic telephone dialing systems. Standard message and data rates applied by your cell phone carrier may apply to the text messages we send you.

You may opt-out of receiving promotional email communications we send to you by following the unsubscribe options on such emails. You may opt out of any promotional phone calls by informing the caller that you would not like to receive future promotional calls. You acknowledge that opting out of receiving communications may impact your use of the Services.

**OUR RIGHTS**

We reserve the right to modify or terminate the Services for any reason, without notice at any time. Not all Services and features are available in every jurisdiction, and we are under no obligation to make any Services or features available in any jurisdiction. We reserve the right to refuse service to anyone for any reason at any time. We may, but have no obligation to, remove any content and suspend or terminate Accounts if we determine in our sole discretion that the transaction being made is a fraudulent one or suspect any form of illegal activity and such violate our Acceptable Use Policy or these Terms of Use. In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership.

**Disclaimer of Warranties**

YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR SOLE RISK. EVERFINANCE EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. EVERFINANCE MAKES NO WARRANTY THAT THE SERVICES WILL (1) BE NOT UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (II) MEET YOUR REQUIREMENTS OR EXPECTATIONS; OR (III) BE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS DEVICE, OR DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL.

**Limitation of Liability**

YOU AGREE THAT MommyandMeCare (TOGETHER WITH ANY AFFILIATES, JOINT VENTURE PARTNERS, OR OTHER COMPANIES IT CONTROLS OR IS UNDER COMMON CONTROL WITH, TO INCLUDE EMPLOYEES, OFFICERS, AND DIRECTORS OF SUCH ENTITIES OR THE OTHER COMPANIES ON ITS PLATFORM) SHALL, IN NO EVENT, BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION (INCLUDING USER & CLIENT DATA), OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR INABILITY TO USE OUR SERVICES, EVEN IF EVERFINANCE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EVERFINANCE SHALL NOT BE HELD LIABLE FOR ANY LOSS OR DAMAGE THAT OCCURS FROM USERS’ INTERACTIONS OR TRANSACTIONS WITH MERCHANTS. THE PRODUCTS SOLD BY MERCHANTS ARE NOT INVESTIGATED, MONITORED, OR CHECKED FOR ACCURACY, RELIABILITY, OR WHOLENESS BY MommyandMeCare. BY GRANTING USERS ACCESS TO PRODUCTS AND SERVICES, MommyandMeCare DOES NOT IMPLY APPROVAL OR ENDORSEMENT OF SUCH PRODUCTS. YOU AGREE THAT MommyandMeCare IS NOT RESPONSIBLE OR LIABLE FOR THE PRODUCTS YOU PURCHASE FROM MERCHANTS. FURTHERMORE, PLEASE NOTE THAT MommyandMeCare IS MERELY A PLATFORM TO ENABLE CRYPTOCURRENCY TRANSACTIONS BETWEEN MERCHANTS AND USERS AND MommyandMeCare WILL NOT BE HELD LIABLE FOR DISPUTES (INCLUDING BUT NOT LIMITED TO DISPUTES IN

RESPECT OF THE CONFIRMATION OF PAYMENT OR RECEIPT OF PAYMENT MADE; DISPUTES IN RESPECT OF THE VALIDATION OF THE DELIVERY OF PRODUCTS AND COMMODITIES BY A MERCHANT THROUGH ANY DELIVERY CHANNEL AND UNTO A USER; DISPUTES IN RESPECT OF THE FITNESS FOR PURPOSE AND THE MERCHANTABLE QUALITY OF PRODUCTS AND SERVICES PROVIDED BY MERCHANTS) THAT MAY ARISE BETWEEN A MERCHANT AND A USER. WE EXPECT YOU TO CONDUCT YOUR DUE DILIGENCE ON THE MERCHANTS THAT YOU PATRONIZE.

**Discontinued Use**

If you are dissatisfied with any portion of this Platform, or with any of these Terms and Conditions, your sole and exclusive remedy is to discontinue using the Platform.

**Third-Party Promotions**

We shall have no liability whatsoever in respect of any advertisements, campaigns or other promotions of other companies and third parties that appear on the Platform.

**Linked Websites**

The Platform may contain links to other websites (“Linked Sites”). The Linked Sites are not under our control, and we are not responsible for the contents of any Linked Site, including, without limitation, any link contained in a Linked Site, or any changes or updates to a Linked Site. Linked Sites are provided

to you only as a convenience, and the inclusion of any link does not imply endorsement by us of the website, the operator of the website, the website’s content or any association by us with its operators. Links to other websites should not be construed as any endorsement, approval or recommendation by us of the materials on, or operators of, such websites.

**Indemnification**

By using the Services you agree to indemnify and hold harmless EverFinance, its officers, directors, employees, suppliers, and affiliates, from and against any losses, damages, fines, and expenses (including attorney’s fees and costs) arising out of or relating to any claims that you have used the Services in violation of another party’s rights, in violation of any law, in violation of any provisions of this Agreement, or any other claim related to your use of the Services, except where such use is authorized by EverFinance. You agree to release, indemnify and keep indemnified us from and against all actions, claims, costs (including legal costs and expenses), losses, proceedings, damages, liabilities, or demands suffered or incurred by us to any person arising out of or in connection with your failure to comply with these Platform’s Terms and Conditions.

Accordingly, you irrevocably undertake to indemnify EverFinance and keep it fully indemnified against all losses, claims, demands, liabilities actions, proceedings, damages, fees and expenses which it may incur or suffer as a result of transactions performed by you, your Customers or affiliates on MommyandMeCare platform. You further undertake that MommyandMeCare shall not be held liable by you or any person acting on your behalf or transacting with you for any fraudulent transactions from your use of its platform. In the event of any loss or damage suffered by EverFinance due to your acts or omission or those of your customers, you agree to authorize EverFinance to debit any amount standing to your credit with EverFinance towards remedying the loss or damage it may have suffered. This indemnity shall not be in any way discharged or diminished, nor shall your liability to MommyandMeCare be affected by reason of any other guarantee, indemnity, assurance, pledge, lien, bill, note, mortgage, charge, debenture, security, or other rights, power or remedy, or any payment being or becoming wholly or in part invalid, defective, or unenforceable, or otherwise failing to be perfected or enforced, or being avoided on any ground whatsoever. This indemnity shall be construed in all respects in accordance with the Laws of the State of Delaware, United States of America as may be in force from time to time.

**Dispute Resolution**

- Where a dispute arises by using the Services, the Parties agree to settle such dispute amicably within 8 (Eight) weeks from the date the dispute is first raised by the dissatisfied party.

- Without prejudice to the paragraph above, disputes arising out of or in connection with this Agreement that cannot be settled amicably shall be resolved in accordance with the laws of the state of Delaware as amended from time to time, or any amendment or re-enactment thereof.

- In any case of dispute between a merchant and a user, the user is expected to resolve the dispute directly with the merchant. You agree that the outcome of your dispute with the merchant will not affect our rights or remedies under this Agreement.

**Termination**

MommyandMeCare, may immediately suspend or terminate your account (in whole or part) with prejudice in event of :

i. Its reasonable belief that you have provided untrue, inaccurate, outdated, or incomplete information in registering for or maintaining your account;

ii. Your non-payment for any fees related to the Services (if applicable);

iii. Any suspected illegal or fraudulent activity in your use of the Services;

iv. Requests by law enforcement or other government agencies; or

v. Its reasonable belief that you have violated the terms of this Agreement or have or are conducting any fraudulent activity or identity theft.

In the event this Agreement is terminated with prejudice, your right to access or use User Data shall immediately cease, and EverFinance reserves the right to withhold, remove and/or discard User Data, without notice or liability.

**Cancellation and Termination**

a. You may choose to delete your Account and terminate the Terms of Use at any time without an additional charge/fee by contacting our Support via hello@evarfinance.com and then following the specific instructions indicated to you in our response. However, you shall remain liable for all pending obligations related to your Account. Upon closure of your Account, we shall cancel any scheduled or incomplete transactions. You are advised to stop all active recurring crypto payments on your Account before closing it.

b. Please note that you may not be able to close your Account, If:

i. There is an ongoing investigation on the Account

ii. There is a dispute or claim on the Account

iii. you have an active recurring payment or pending transaction.

iv. If your Account has pending settlements.

v. If your Account is subject to a hold, limitation, or reserve

**Fraud: **Without limiting any other remedies, MommyandMeCare may suspend or terminate your Account if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the use of the Services.

**PRIVACY & DATA PROTECTION**

MommyandMeCare is firmly committed to protecting the privacy of both merchants’ and customers’ personal information. By using the Service, you acknowledge and agree that our collection, usage and disclosure of this personal information is governed by our DATA PRIVACY, PROTECTION & COOKIE NOTICE.

**MISCELLANEOUS**

**Force Majeure**

Where MommyandMeCare performance hereunder is rendered inadvisable, commercially impracticable, illegal, or impossible to perform by elements of nature, acts of God, acts of war, acts or threats of terrorism, pandemics, or other causes outside of its reasonable control, MommyandMeCare, upon giving prompt Notice to the other party, will be excused totally (without any liabilities or damages whatsoever) from performance for the duration of the condition, provided that it uses commercially reasonable efforts to mitigate the effects.

**Waiver**

All waivers must be in writing. A party’s consent to, or waiver of, enforcement of this Agreement on one occasion will not be deemed a waiver of any other provision or such provision on any other occasion.

**Entire Agreement/Amendment**

This Agreement represents the entire agreement of the parties and supersedes all prior or contemporaneous agreements, proposals or representations, written or oral, concerning its subject matter.

**Notice**

MommyandMeCare may give notice to you by means of a general notice through the Services, electronic mail to your email address on record in your account information, or by written communication sent by first class mail to your address on record in your account information. Such notice shall be deemed to have been given upon the expiration of Forty-Eight (48) hours after mailing or posting (if sent by first class mail or pre-paid post) or twelve (12) hours after sending (if sent electronically). You may give notice to MommyandMeCare (deemed given upon receipt by MommyandMeCare) by any of the following: a letter delivered by a nationally recognized overnight delivery service or first-class postage prepaid mail to:

Address:

Attention: Legal Department

Email: hello@evarfinance.com

**No Liability for Third-Party Services Linked From Site**

In using our Services, you may enter into a business relationship with a third party offering its services through the Services. Any such activity is solely between you and the relevant third party. EverFinance does not endorse any such third parties and shall have no liability, obligation, or responsibility

for any such business activity.

**Assignment**

Except as otherwise provided in this Agreement, you may not assign this Agreement without the prior express written consent of MommyandMeCare. This Agreement may be assigned without Grantee’s consent by MommyandMeCare to:

i. an affiliate;

ii. an acquirer of MommyandMeCare business or assets; or

iii. a successor by merger or equity purchase.

**Governing Law**

These Terms shall be governed by the Laws of the State of Delaware, United States of America.

**Severability**

In the event that any provision of this Agreement is declared illegal or unenforceable and cannot be modified to be enforceable, such provision shall immediately become null and void, leaving the remainder of this Agreement in full force and effect.
`;
