import React from 'react';
import { useTheme } from 'styled-components';

import { setSound } from '@app/providers/store/settings/actions/settings';

import { Logo } from '../logo/Logo';
import { SoundToggle } from '../soundToogle/SoundToogle';
import { useDispatch, useSelector } from '../../providers/store';
import { DarkModeToggle } from '../darkModeToggle/DarkModeToggle';

import {
  Svg,
  Button,
  Navlist,
  NavLeft,
  Listitem,
  RssWrapper,
  IconWrapper,
  RightMobile,
  ToggleWrapper,
  RightDesktop,
  LayoutWrapper,
  HeaderWrapper,
  NavLinkWrapper,
  MaxWidthWrapper,
} from './Header.styles';

export const Header = () => {
  const dispatch = useDispatch();
  const { toggleTheme, isDarkMode } = useTheme();
  const { soundEnabled } = useSelector(({ settings }) => settings);

  const toggleSound = () => dispatch(setSound());

  return (
    <LayoutWrapper>
      <MaxWidthWrapper>
        <HeaderWrapper>
          <NavLeft>
            <Logo />
            <NavLinkWrapper>
              <Navlist>
                <Listitem></Listitem>
              </Navlist>
            </NavLinkWrapper>
          </NavLeft>

          <RightDesktop>
            <IconWrapper>
              <ToggleWrapper>
                <DarkModeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
              </ToggleWrapper>
              <ToggleWrapper>
                <SoundToggle toggleSound={toggleSound} soundEnabled={soundEnabled} />
              </ToggleWrapper>
              <RssWrapper>
                <Button>
                  <Svg
                    width="22px"
                    height="22px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <title id="rss-title">RSS Feed</title>
                    <path d="M4 11a9 9 0 0 1 9 9"></path>
                    <path d="M4 4a16 16 0 0 1 16 16"></path>
                    <circle cx="5" cy="19" r="1"></circle>
                  </Svg>
                </Button>
              </RssWrapper>
            </IconWrapper>
          </RightDesktop>

          <RightMobile>
            <DarkModeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
            <SoundToggle toggleSound={toggleSound} soundEnabled={soundEnabled} />
          </RightMobile>
        </HeaderWrapper>
      </MaxWidthWrapper>
    </LayoutWrapper>
  );
};
