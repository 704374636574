import styled from 'styled-components';

export const MaxWidthWrapper = styled.div`
  z-index: 2;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  padding-bottom: 50px;
  padding-right: 32px;
  padding-left: 32px;
  font-weight: var(--font-weight-regular);

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Main = styled.main`
  padding: 70px 0 0 0;
  padding-top: 70px;
`;

export const BlogWrapper = styled.div`
  &:not(:first-of-type) {
    margin-top: 64px;
  }
`;

export const BlogTitle = styled.h3`
  padding-left: 32px;
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: var(--font-weight-bold);

  @media (min-width: 1150px) {
    padding-left: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  font-weight: var(--font-weight-regular);

  @media (min-width: 1150px) {
    margin-left: -32px;
    margin-right: -32px;
  }

  @media ${(p) => p.theme.breakpoints.smAndSmaller} {
    grid-template-columns: 1fr;
  }
`;

export const span = styled.span`
  color: white;
  font-size: 18px;
  font-weight: var(--font-weight-medium);
  text-shadow: rgb(0 0 0 / 15%) 1px 1px 1px;
`;

export const Spacer = styled.div<{ size?: number }>`
  min-width: ${({ size }) => size || 45}px;
  min-height: ${({ size }) => size || 45}px;
`;
