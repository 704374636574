import { network } from '@app/config';
import { Article, IPaginateArticles } from '@app/providers/store/articles/models';

type BasicResponseInterface<T> = {
  payload: T;
  status: number;
  message: string;
};

/**
 *
 * @description get mmc blog posts
 * @function getLatestArticles
 * @returns Promise<Article[]>
 */
export const getArticles = async () => {
  const { data } = await network.get<BasicResponseInterface<IPaginateArticles>>('/blog');
  return data;
};

/**
 *
 * @description get mmc blog posts
 * @function getArticle
 * @returns Promise<Article>
 */
export const getArticle = async (slug: string) => {
  const { data } = await network.get<BasicResponseInterface<Article>>(`/blog/${slug}`);
  return data;
};

/**
 *
 * @description subscribe to mmc blog posts
 * @function subscribeToNewsLetter
 * @returns Promise<Article>
 */
export const subscribeToNewsLetter = async (payload: {
  full_name: string;
  email_address: string;
}) => {
  const { data } = await network.post<BasicResponseInterface<Article>>('/blog/subscribe', payload);
  return data;
};
