import React from 'react';
import { NavLink } from 'react-router-dom';

import { Next, Title, Content, Description, ExternalLink, MaxWidthWrapper } from './notFound.style';

export const NotFound: React.FC = () => (
  <Next>
    <MaxWidthWrapper>
      <Content>
        <Title>Hmm... seems you're lost</Title>
        <Description>
          That page can’t be reached, but if you double-check it,
          <br />
          we’re happy to try again.
        </Description>
        <NavLink to="/">
          <ExternalLink>← Back to Homepage</ExternalLink>
        </NavLink>
      </Content>
    </MaxWidthWrapper>
  </Next>
);
