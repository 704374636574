import { getLocale } from '@app/helpers';
import { IColorMode, ISettingsState, SettingsActionTypes } from '../models';
import { SET_SOUND, SET_LOCALE, SET_COLOR_MODE, SET_NOTIFICATION } from '../types';

export const initialSettingsState: ISettingsState = {
  soundEnabled: true,
  locale: getLocale(),
  receiveNotifications: true,
  colorMode: IColorMode.LIGHT,
};

export const settingReducer = (
  state: ISettingsState = initialSettingsState,
  action: SettingsActionTypes
): ISettingsState => {
  switch (action.type) {
    case SET_COLOR_MODE:
      return { ...state, colorMode: action.payload };

    case SET_NOTIFICATION:
      return { ...state, receiveNotifications: !state.receiveNotifications };

    case SET_SOUND:
      return { ...state, soundEnabled: !state.soundEnabled };

    case SET_LOCALE:
      return { ...state, locale: action.payload };

    default:
      return state;
  }
};
